<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">El-<span>Wekala</span></a>
  </div>
</div>



<div class="header-container">

  <div *ngIf="canShowPermissions">
    <a href="/#/permissions">
      <nb-icon icon="lock-outline"></nb-icon>
    </a>
  </div>


  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [picture]="getLoggedInImage()" [name]="getLoggedInName()">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
