import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NbAutocompleteModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbInputModule, NbSpinnerModule } from '@nebular/theme';
import { NbTabsetModule } from '@nebular/theme';
// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NbToastrModule } from '@nebular/theme';
import { NbCheckboxModule } from '@nebular/theme';
import { NbSelectModule } from '@nebular/theme';
import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxPermissionsAllowStubModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadImageModule } from './upload-image/upload-image.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
  declarations: [],
  entryComponents: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NbToastrModule.forRoot(),
    NgxPermissionsAllowStubModule,
    FlexLayoutModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    NbSpinnerModule,
    UploadImageModule,
    NgxMaskModule.forRoot(options),
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NbCardModule,
    NbTabsetModule,
    // Ng2SmartTableModule,
    NbCheckboxModule,
    NbSelectModule,
    DropzoneModule,
    FlexLayoutModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbAutocompleteModule,
    NbSpinnerModule,
    UploadImageModule,
    NgxMaskModule,
  ]
})
export class SharedModule {
}
