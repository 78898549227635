import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from '../services/login/login.services';

@Injectable({
  providedIn: 'root',
})
export class OnlyLoggedInUsers implements CanActivate {
  constructor(public loginService: LoginService,
              public router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.loginService.LoggedIn) {
      this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
    } else {
      return true;
    }
  }
}
