import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbAuthComponent } from '@nebular/auth';
import { OnlyLoggedInUsers } from './guards/only-logged-in-users.guard';


export const routes: Routes = [
  {
    path: '',
    component: NbAuthComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'permissions',
    loadChildren: () => import('./pages/permissions/permissions.module').then(m => m.PermissionsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'admins',
    loadChildren: () => import('./pages/admins/admins.module').then(m => m.AdminsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'categorise',
    loadChildren: () => import('./pages/categorise/categorise.module').then(m => m.CategoriseModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'sizes',
    loadChildren: () => import('./pages/sizes/sizes.module').then(m => m.SizesModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), canActivate: [OnlyLoggedInUsers]
  },
  // {
  //   path: 'products',
  //   loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), canActivate: [OnlyLoggedInUsers]
  // },
  {
    path: 'sliders',
    loadChildren: () => import('./pages/tvs/tvs.module').then(m => m.TvsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'colors',
    loadChildren: () => import('./pages/colors/colors.module').then(m => m.ColorsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'badges',
    loadChildren: () => import('./pages/badges/badges.module').then(m => m.BadgesModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'packing-units',
    loadChildren: () => import('./pages/packing-units/PackingUnits.module').then(m => m.PackingUnitsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'materials',
    loadChildren: () => import('./pages/materials/materials.module').then(m => m.MaterialsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'policies',
    loadChildren: () => import('./pages/policies/policies.module').then(m => m.PoliciesModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'warehouses',
    loadChildren: () => import('./pages/warehouses/warehouses.module').then(m => m.WarehousesModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'shipping-methods',
    loadChildren: () => import('./pages/shipping-methods/shipping-methods.module').then(m => m.ShippingMethodsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('./pages/payment-methods/payment-methods.module').then(m => m.PaymentMethodsModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'sections-collection',
    loadChildren: () => import('./pages/sections_collection/sections-collection.module').then(m => m.SectionsCollectionModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersModule), canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'sellers',
    loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule),
    // data: { store_type_id: 2, store_type: "seller" },
    canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'stores',
    loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule),
    // data: { store_type_id: 1, store_type: "store" },
    canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [OnlyLoggedInUsers]
  },
  {
    path: 'places',
    loadChildren: () => import('./pages/places/places.module').then(m => m.PlacesModule),
    canActivate: [OnlyLoggedInUsers]
  },
  // {
  //   path: 'add/product',
  //   loadChildren: () => import('./pages/add-product/add-product.module').then(m => m.AddProductModule),
  //   canActivate: [OnlyLoggedInUsers]
  // },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsModule),
  },
  {
    path: '**',
    redirectTo: '404'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
