import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LocalStorageHandler } from '../lib/local-storage/LocalStorageHandler';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handler = new LocalStorageHandler();
    const admin = JSON.parse(handler.getItem('admin'));
    let token = '';

    if (admin !== null && admin !== undefined) {
      token = admin.token;
    }

    const authReq = req.clone({
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'X-localization': 'en'
      }),
    });

    return next.handle(authReq)
      .pipe(
        tap(() => {
        }, error => {
          if (error.status === 401) {
            window.localStorage.clear();
            window.location.reload();
          }
        }),
      );
  }
}
