import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'General',
    group: true,
  },
  {
    title: 'Dashboard',
    icon: 'home',
    link: '/dashboard',
    home: true,
  },
  {
    title: 'Settings',
    group: true,
  },
  // {
  //   title: 'Categories',
  //   icon: 'list-outline',
  //   link: '/categorise',
  // },
  {
    title: 'Manage Categories',
    icon: 'list-outline',
    children: [
      {
        title: 'Main Categories',
        icon: 'cube-outline',
        link: '/categories/main',
      },
      {
        title: 'Sub Categories',
        icon: 'cube-outline',
        link: '/categories/sub',
      },
      {
        title: 'Child Categories',
        icon: 'cube-outline',
        link: '/categories/child',
      }
    ]
  },
  {
    title: 'Sizes',
    icon: 'move-outline',
    link: '/sizes',
  },
  {
    title: 'Products',
    icon: 'cube-outline',
    children: [
      {
        title: 'Seller Products',
        icon: 'cube-outline',
        children: [
          {
            title: 'Products List',
            link: '/products/sellers',
          },
          {
            title: 'Pending Products',
            link: '/products/sellers/pending',
          },
          {
            title: 'Add Products',
            link: '/products/sellers/add',
          },
        ]
      },
      {
        title: 'Store Products',
        icon: 'cube-outline',
        children: [
          {
            title: 'Products List',
            link: '/products/stores',
          },
          {
            title: 'Pending Products',
            link: '/products/stores/pending',
          },
          {
            title: 'Add Products',
            link: '/products/stores/add',
          },
        ]
      },
      // {
      //   title: 'All Products',
      //   icon: 'cube-outline',
      //   link: '/products',
      // },
      // {
      //   title: 'Add Product',
      //   icon: 'cube-outline',
      //   children: [
      //     {
      //       title: 'Supplier',
      //       link: '/add/product/supplier',
      //     },
      //     {
      //       title: 'Retailer',
      //       link: '/add/product/retailer',
      //     },
      //   ]
      // }
    ]
  },
  {
    title: 'Brands',
    icon: 'pricetags-outline',
    link: '/brands',
  },
  // {
  //   title: 'Sellers',
  //   icon: 'people-outline',
  //   link: '/sellers/active-sellers',
  // },
  {
    title: 'Stores',
    icon: 'shopping-cart-outline',
    children: [
      {
        title: 'Stores',
        icon: 'shopping-cart-outline',
        link: '/stores',
      },
      {
        title: 'Pending Verification',
        icon: 'shopping-cart-outline',
        link: '/stores/pending',
      },
    ]
  },
  {
    title: 'Sellers',
    icon: 'people-outline',
    children: [
      {
        title: 'Sellers',
        icon: 'people-outline',
        link: '/sellers',
      },
      {
        title: 'Pending Verification',
        icon: 'people-outline',
        link: '/sellers/pending',
      },
    ]
  },
  {
    title: 'Orders',
    icon: 'shopping-bag-outline',
    link: '/orders',
    children: [
      {
        title: 'Sellers',
        icon: 'gift-outline',
        children: [
          {
            title: 'All Orders',
            icon: 'shopping-bag-outline',
            link: '/orders/seller',
          }, {
            title: 'Pending Orders',
            icon: 'shopping-bag-outline',
            link: '/orders/seller/pending',
          }, {
            title: 'Processing Orders',
            icon: 'shopping-bag-outline',
            link: '/orders/seller/processing',
          }, {
            title: 'Completed Orders',
            icon: 'shopping-bag-outline',
            link: '/orders/seller/completed',
          }, {
            title: 'Declined Orders',
            icon: 'shopping-bag-outline',
            link: '/orders/seller/declined',
          },
        ]
      },
      {
        title: 'Stores',
        icon: 'gift-outline',
        link: '/warehouses/stores',
      },
    ]
  },
  {
    title: 'Sections Collection',
    icon: 'layers-outline',
    link: '/sections-collection',
  },
  {
    title: 'Sliders',
    icon: 'hard-drive-outline',
    link: '/sliders',
  },
  {
    title: 'Colors',
    icon: 'color-palette-outline',
    link: '/colors',
  },
  {
    title: 'badges',
    icon: 'color-palette-outline',
    link: '/badges',
  },
  {
    title: 'Packing Units',
    icon: 'color-palette-outline',
    link: '/packing-units',
  },
  {
    title: 'Materials',
    icon: 'gift-outline',
    link: '/materials',
  },
  {
    title: 'Policies',
    icon: 'gift-outline',
    link: '/policies',
  },
  {
    title: 'Warehouses',
    icon: 'gift-outline',
    children: [
      {
        title: 'Sellers',
        icon: 'gift-outline',
        children: [
          {
            title: 'Warehouses List',
            icon: 'gift-outline',
            link: '/warehouses/sellers',
          },
          {
            title: 'Stock List',
            icon: 'gift-outline',
            link: '/warehouses/sellers/stock',
          },
          {
            title: 'Stock Request',
            icon: 'gift-outline',
            link: '/warehouses/sellers/stock/pending',
          },
        ]
      },
      {
        title: 'Stores',
        icon: 'gift-outline',
        link: '/warehouses/stores',
      },
    ]
  },
  {
    title: 'Offers',
    icon: 'gift-outline',
    link: '/offers',
  },
  {
    title: 'Shipping Methods',
    icon: 'gift-outline',
    link: '/shipping-methods',
  },
  {
    title: 'Payment Methods',
    icon: 'gift-outline',
    link: '/payment-methods',
  },
  {
    title: 'Locations',
    icon: 'map-outline',
    children: [
      {
        title: 'Countries',
        link: '/places/countries',
        icon: 'navigation-2-outline',
      },
      {
        title: 'Regions',
        link: '/places/region',
        icon: 'navigation-2-outline',
      },
      {
        title: 'States',
        link: '/places/states',
        icon: 'navigation-2-outline',
      },
      {
        title: 'Cities',
        link: '/places/cities',
        icon: 'navigation-2-outline',
      }
    ],
  },
  {
    title: 'User',
    group: true,
  },
  {
    title: 'Admins',
    icon: 'person-add-outline',
    link: '/admins',
  },
  {
    title: 'Messages Ex',
    icon: 'message-circle-outline',
    link: '/chat',
  },
  // {
  //   title: 'offers',
  //   icon: 'star',
  //   children: [
  //     {
  //       title: 'Offers',
  //       link: '/offers',
  //     },
  //   ],
  // },
];
