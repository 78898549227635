export class LocalStorageHandler {
  public getItem(key: string): string {
    let value = '';
    if (typeof window !== 'undefined') {
      value = localStorage.getItem(key);
    }
    return value;
  }

  public setItem(key: string, value: string): void {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  }

  public removeItem(key: string): void {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  }
}

export const localStorageHandler = new LocalStorageHandler();
