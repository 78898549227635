import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NbToastrService} from '@nebular/theme';

@Component({
    selector: 'upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    @Output() fileSelected: EventEmitter<File> = new EventEmitter();
    @Output() imageDeleted: EventEmitter<any> = new EventEmitter();

    @Input() image: any = '/assets/icons/picture.svg';
    defualImagetUrl: string = '/assets/icons/picture.svg';
    imageCleared: boolean = false;

    constructor(private toaster: NbToastrService) {
    }

    ngOnInit(): void {
    }

    public handleFileInput(event) {
        debugger;
        const files = event.target?.files;
        const extn = event.target?.files[0].type.split('/')[1];
        const size = event.target?.files[0].size;
        this.fileSelected.emit(files.item(0));
        const valid = ['gif', 'png', 'jpg', 'jpeg'];
        const maxSize = 2000000;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            if (valid.includes(extn)) {
                if (size < maxSize) {
                    this.image = reader.result;
                } else {
                    this.toaster.show('The image maximum size is 2MB!', 'Error', {status: 'danger'});
                }
            } else {
                this.toaster.show('Image type  supports "jpg, gif, png " only!', 'Error', {status: 'danger'});
            }
        };
        this.imageCleared = false;
    }

    clearImage() {
        this.image = '/assets/icons/picture.svg';
        this.imageCleared = true;
        this.imageDeleted.emit(true);
    }

}
