import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadImageComponent } from './upload-image.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NbButtonModule, NbIconModule } from '@nebular/theme';


@NgModule({
  declarations: [UploadImageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    NbIconModule,
    NbButtonModule
  ],
  exports: [
    UploadImageComponent
  ]
})
export class UploadImageModule { }
