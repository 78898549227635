import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public LoggedIn = false;
  public admin: any;

  constructor(private http: HttpClient) {
    this.admin = JSON.parse(localStorage.getItem('admin'));
    this.LoggedIn = !!localStorage.getItem('admin');
  }

  public login(form) {
    const url = environment.MAIN_SYSTEM + 'api/dashboard/login';
    return this.http.post(url, form);
  }
}
