<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">

    <div fxLayout="row" fxLayoutAlign="center center" class="input-button">
        <img [src]="image ? image : defualImagetUrl" alt="url image">

        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx" class="button-container">
            <button nbButton status="info" [fxFlex]="100">
                <input type="file" id="file" (change)="handleFileInput($event)">
                <nb-icon icon="camera-outline"></nb-icon>
            </button>

            <button nbButton status="danger" [fxFlex]="100" (click)="clearImage()" *ngIf="image && !imageCleared">
                <nb-icon icon="trash-outline"></nb-icon>
            </button>
        </div>


    </div>

</div>